/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-vote-statistics-vote__container {
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em 1.77em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    font-size: 18px;
    align-self: flex-start;
    width: 68.889vw;
    position: relative;
}
.details-vote-statistics-vote-title-block {
    display: flex;
    flex-direction: row;
    gap: 0.44em;
}
.details-vote-statistics-vote-title-block-voted {
    display: flex;
    flex-direction: row;
    gap: 0.44em;
}
.details-vote-statistics-vote__title {
    margin-bottom: 1em;
    font-size: 1.33rem;
    font-weight: 600;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__title-voted-mobile {
    display: none;
}
.details-vote-statistics-vote__title-and {
    margin-bottom: 1em;
    font-size: 1.33rem;
    font-weight: 600;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__title-mobile {
    display: none;
}
.details-vote-statistics-vote__last-update {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.44em;
}
.details-vote-statistics-vote__last-update-label {
    font-size: 1em;
    line-height: 111%;
    margin-right: 0.55em;
    color: rgba(54, 59, 77, 0.4);
    font-weight: 500;
}
.details-vote-statistics-vote__last-update img {
    width: 0.88em;
    height: 0.88em;
    margin-right: 0.33em;
}
.details-vote-statistics-vote__last-update-icon-label {
    font-weight: 400;
    font-size: 1em;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.6);
    margin-right: 0.5em;
}
/*------Контейнер для результатов регистрации и диаграммы-------------------------------------------------------------*/
.details-vote-general-info__results-diagramm-block {
    display: grid;
    grid-template-areas: "result."
                         "diagramm statusEvent";
    grid-auto-rows: auto 1fr;
    grid-template-columns: 17.83em 1fr;
    grid-row-gap: 1.33em;
    margin-bottom: 1.77em;
}
.details-vote-statistics-vote__result-voted-registred-block {
    grid-area: result;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.details-vote-statistics-vote__result-registred {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
.details-vote-statistics-vote__result-voted {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
.details-vote-statistics-vote__result-registred-items,
.details-vote-statistics-vote__result-voted-items {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    gap: 0.54em;
}
.details-vote-statistics-vote__result-registred-label,
.details-vote-statistics-vote__result-voted-label {
    font-size: 1em;
    line-height: 156%;
    color: rgba(54, 59, 77, 1);
}
.details-vote-statistics-vote__result-registred-icon,
.details-vote-statistics-vote__result-voted-icon {
    width: 0.66em;
    height: 0.66em;
}
.details-vote-statistics-vote__diagramm-block {
    grid-area: diagramm;
    display: flex;
    position: relative;
    width: 15.16em;
    height: 15.16em;
}
/*------Стили для кругов диаграммы-----------------------------------------------------------------------------------*/
.diagramm-circle {
    width: 15.16em;
    height: 15.16em;
}
/*------При-увеличении dashoffset у green, надо уменьшать dasharray у желтого-----------------------------------------*/
.circle__style {
    fill: none;
    stroke-width: 5;
}
.details-vote-statistics-vote__diagramm-block-numbers-reg-voting {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.details-vote-statistics-vote__diagramm-block-numbers-voting {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 40%;
    left: 30%;
}
.details-vote-statistics-vote__diagramm-num-reg,
.details-vote-statistics-vote__diagramm-num-vote {
    font-size: 1em;
    line-height: 156%;
    color: rgba(54, 59, 77, 1);
}
.details-vote-statistics-vote__diagramm-label-reg,
.details-vote-statistics-vote__diagramm-label-vote {
    font-size: 0.77em;
    color: rgba(54, 59, 77, 0.4);
    line-height: 129%;
    margin-bottom: 0.33em;
}

.details-vote-statistics-vote__status-event-quorum-condition {
    grid-area: statusEvent;
    display: flex;
    justify-content: center;
    flex-direction: column;
 }
.details-vote-statistics-vote__quorum,
.details-vote-statistics-vote__status-event {
    display: flex;
    flex-direction: column;
    gap: 0.44em;
    margin-bottom: 1.77em;
}
.details-vote-statistics-vote__quorum-label,
.details-vote-statistics-vote__status-event-label,
.details-vote-statistics-vote__progress-visit-vote-label {
    font-size: 0.88em;
    line-height: 150%;
    color: rgba(54, 59, 77, 0.4);
}
.details-vote-statistics-vote__quorum-note {
    word-break: break-word;
    font-size: 1em;
    line-height: 156%;
    color: rgba(54, 59, 77, 1);
}
.details-vote-statistics-vote__status-event-icon {
    display: inline-flex;
    align-items: center;
    gap: 0.44em;
    font-size: 1em;
    color: #4ED4A9;
    line-height: 133%;
}
.details-vote-statistics-vote__status-event-icon-not-reached {
    display: inline-flex;
    align-items: center;
    gap: 0.44em;
    font-size: 1em;
    color: #FF4970;
    line-height: 133%;
}
.details-vote-statistics-vote__status-event-icon img {
    width: 0.94em;
    height: 0.72em;
}
.details-vote-statistics-vote__status-event-icon-not-reached img {
    width: 0.94em;
    height: 0.72em;
}
.details-vote-statistics-vote__progress-visit-vote {
    display: flex;
    flex-direction: column;
    gap: 0.44em;
}
.details-vote-statistics-vote__progress {
    max-width: 375px;
    background-color: rgba(54, 59, 77, 0.1);
    border-radius: 4px;
}
.details-vote-statistics-vote__progress-bar {
    width: 70%;
    height: 0.44em;
    background-color: #49B3FF;
    border-radius: 4px;
}
.details-vote-statistics-vote-update-statistics-vote {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.88em;
    margin-bottom: 1.33em;
}
.details-vote-statistics-vote-update-statistics-vote__button {
    width: 18.66em;
    height: 3em;
    border-radius: 0.44em;
    background: none;
    font-size: 0.88em;
    border: 1px solid #0084FE;
    color: #0084FE;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.details-vote-statistics-vote-update-statistics-vote__button:hover {
    background: #0084FE;
    color: #FFFFFF;
}
.details-vote-statistics-vote-update-statistics-vote__message {
    color: #4ED4A9;
    font-size: 0.88em;
}
.details-vote-statistics-vote__show-more-btn {
    font-size: 0.88em;
    font-weight: 300;
    color: #0084FE;
    line-height: 150%;
    cursor: pointer;
}
.details-vote-statistics-vote__show-more-btn.hidden {
    display: none;
}
/*------Контейнер для динамики явки-----------------------------------------------------------------------------------*/
.details-vote-statistics-vote__dynamics-visit {
    display: flex;
    flex-direction: column;
}
.details-vote-statistics-vote__dynamics-visit.hidden {
    display: none;
}
.details-vote-statistics-vote__dynamics-visit-title {
    line-height: 133%;
    font-weight: 600;
    font-size: 1.33rem;
    color: rgba(54, 59, 77, 1);
    margin-bottom: 1.33em;
}
.details-vote-statistics-vote__dynamics-visit-change-graph-table {
    display: inline-flex;
    gap: 0.44em;
    margin-bottom: 1.33em;
}
.details-vote-statistics-vote__change-graph-btn {
    width: 2.66em;
    height: 2.66em;
    cursor: pointer;
    background-image: url("../../img/DetailsVoteStatisticsVoteIconGraph.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.details-vote-statistics-vote__change-table-btn {
    width: 2.66em;
    height: 2.66em;
    cursor: pointer;
    background-image: url("../../img/DetailsVoteStatisticsVoteIconTable.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.details-vote-statistics-vote__change-graph-btn:hover {
    background-image: url("../../img/DetailsVoteStatisticsVoteIconGraphActive.svg");
    width: 2.66em;
    height: 2.66em;
}
.details-vote-statistics-vote__change-table-btn:hover {
    background-image: url("../../img/DetailsVoteStatisticsVoteIconTableActive.svg");
    width: 2.66em;
    height: 2.66em;
}
.details-vote-statistics-vote__change-graph-btn.active {
    background-image: url("../../img/DetailsVoteStatisticsVoteIconGraphActive.svg");
    width: 2.66em;
    height: 2.66em;
}
.details-vote-statistics-vote__change-table-btn.active {
    background-image: url("../../img/DetailsVoteStatisticsVoteIconTableActive.svg");
    width: 2.66em;
    height: 2.66em;
}
/*------Контейнер для графика динамики явки---------------------------------------------------------------------------*/
.details-vote-statistics-vote__dynamics-visit-graph {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
}
.details-vote-statistics-vote__visit-graph-explanations {
    display: inline-flex;
    margin-bottom: 0.88em;
    gap: 0.88em;
}
.details-vote-statistics-vote__visit-graph-explanations-general-visit,
.details-vote-statistics-vote__visit-graph-explanations-voting-visit {
    display: inline-flex;
    gap: 0.44em;
}
.details-vote-statistics-vote__visit-graph-explanations-general-visit img,
.details-vote-statistics-vote__visit-graph-explanations-voting-visit img {
    width: 0.88em;
}
.details-vote-statistics-vote__visit-graph-explanations-general-visit p,
.details-vote-statistics-vote__visit-graph-explanations-voting-visit p {
    line-height: 156%;
    color: rgba(54, 59, 77, 1);
    font-size: 1em;
    font-weight: 400;
}
.details-vote-statistics-vote__visit-graph {
    position: relative;
    height: 453px;
    width: 100%;
}
.details-vote-statistics-vote__hidden-block {
    position: absolute;
    left: 2em;
    width: 45em;
    height: 25px;
    background: #FFFFFF;
    top: -2px;
}
/*------Контейнер для графика таблицы явки----------------------------------------------------------------------------*/
.details-vote-statistics-vote__dynamics-visit-table {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
    max-width: 928px;
}
.details-vote-statistics-vote__visit-table {
    display: flex;
    flex-direction: column;
    border-radius: 1.33em;
    border: 1px solid rgba(54, 59, 77, 0.4);
}
.details-vote-statistics-vote__visit-table-header {
    display: inline-flex;
}
.details-vote-statistics-vote__visit-table-header-time {
    width: 13.61em;
    margin-right: 1.77em;
    border-right: 1px solid rgba(54, 59, 77, 0.4);
    padding: 1.22em 1.77em 1.22em 1.77em;
    font-size: 1em;
    font-weight: 500;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__visit-table-header-visit-time {
    width: 13.61em;
    margin-right: 1.77em;
    border-right: 1px solid rgba(54, 59, 77, 0.4);
    padding: 1.22em 1.77em 1.22em 0;
    font-size: 1em;
    font-weight: 500;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__visit-table-header-general-visit {
    width: 13.61em;
    margin-right: 1.77em;
    padding: 1.22em 1.77em 1.22em 0;
    font-size: 1em;
    font-weight: 500;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__visit-table-row {
    display: inline-flex;
    border-top: 1px solid rgba(54, 59, 77, 0.4);
}
.details-vote-statistics-vote__visit-table-row-colimn-time {
    width: 13.61em;
    margin-right: 1.77em;
    border-right: 1px solid rgba(54, 59, 77, 0.4);
    padding: 1.22em 1.77em 1.22em 1.77em;
    font-size: 1em;
    font-weight: 400;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__visit-table-row-colimn-visit-time {
    width: 13.61em;
    margin-right: 1.77em;
    border-right: 1px solid rgba(54, 59, 77, 0.4);
    padding: 1.22em 1.77em 1.22em 0;
    font-size: 1em;
    font-weight: 400;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__visit-table-row-colimn-general-visit {
    width: 13.61em;
    margin-right: 1.77em;
    padding: 1.22em 1.77em 1.22em 0;
    font-size: 1em;
    font-weight: 400;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
}
.details-vote-statistics-vote__roll-up-btn {
    font-size: 0.88em;
    font-weight: 300;
    color: #0084FE;
    line-height: 150%;
    cursor: pointer;
}
.details-vote-statistics-vote__roll-up-btn.hidden {
    display: none;
}
.details-vote-statistics-vote__empty-data-visit-voters {
    font-size: 1em;
}
.details-vote-statistics-vote__empty-table-date {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(54, 59, 77, 0.4);
    font-size: 1em;
    padding: 0.88em 0 0.88em 0;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1420px) {
    .details-vote-statistics-vote__container {
        font-size: 16px;
    }
}
@media (max-width: 1265px) {
    .details-vote-statistics-vote__container {
        font-size: 14px;
    }
}
@media (max-width: 1115px) {
    .details-vote-statistics-vote__container {
        font-size: 12px;
    }
    .details-vote-statistics-vote__visit-graph {
        height: 300px;
    }
    .details-vote-statistics-vote__hidden-block {
        width: 50em;
    }
}
@media (max-width: 960px) {
    .details-vote-statistics-vote__container {
        font-size: 10px;
    }
}
@media (max-width: 810px) {
    .details-vote-statistics-vote__container {
        font-size: 8px;
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 650px) {
    .details-vote-statistics-vote__title-mobile {
        display: initial;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 144%;
        font-weight: 500;
        color: rgba(54, 59, 77, 1);
    }
    .details-vote-statistics-vote-title-block {
        gap: 0.88em;
    }
    .details-vote-statistics-vote__title-voted-mobile {
        display: flex;
        flex-direction: row;
        gap: 0.88em;
    }
    .details-vote-statistics-vote__title-and {
        font-size: 18px;
        font-weight: 500;
        line-height: 138%;
        margin-bottom: 0;
    }
    .details-vote-statistics-vote-title-block-voted {
        display: none;
    }
    .details-vote-statistics-vote__container {
        padding: 16px 16px 23px 16px;
        width: 100%;
    }
    .details-vote-statistics-vote__title {
        font-size: 18px;
        font-weight: 500;
        line-height: 138%;
        margin-bottom: 0;
    }
    .details-vote-statistics-vote__last-update {
        margin-bottom: 16px;
    }
    .details-vote-statistics-vote__last-update-label {
        font-size: 14px;
        line-height: 157%;
        font-weight: 400;
        margin-right: 6px;
    }
    .details-vote-statistics-vote__last-update img {
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
    .details-vote-statistics-vote__last-update p {
        font-size: 13px;
        margin-right: 6px;
    }
    .details-vote-general-info__results-diagramm-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .details-vote-statistics-vote__diagramm-block{order: 0}
    .details-vote-statistics-vote__result-voted-registred-block{order: 1}
    .details-vote-statistics-vote__status-event-quorum-condition{order: 2}
    .details-vote-statistics-vote__diagramm-block {
        width: 100%;
        height: 239px;
        margin: 0 auto;
    }
    .diagramm-circle {
        width: 239px;
        height: 239px;
    }
    .details-vote-statistics-vote__diagramm-block-numbers-reg-voting {
        margin: 0 auto;
        top: 85px;
        left: 50px;
    }
    .details-vote-statistics-vote__diagramm-block-numbers-voting {
        margin: 0 auto;
        top: 85px;
        left: 65px;
    }
    .details-vote-statistics-vote__diagramm-num-reg,
    .details-vote-statistics-vote__diagramm-num-vote {
        font-size: 18px;
        line-height: 156%;
    }
    .details-vote-statistics-vote__diagramm-label-reg,
    .details-vote-statistics-vote__diagramm-label-vote {
        font-size: 14px;
    }
    .details-vote-statistics-vote__result-registred {
        flex-direction: column;
        gap: 8px;
    }
    .details-vote-statistics-vote__result-voted {
        flex-direction: column;
        gap: 8px;
    }
    .details-vote-statistics-vote__result-registred-items,
    .details-vote-statistics-vote__result-voted-items {
        gap: 8px;
    }
    .details-vote-statistics-vote__result-registred-icon,
    .details-vote-statistics-vote__result-voted-icon {
        width: 8px;
        height: 8px;
    }
    .details-vote-statistics-vote__result-registred-label,
    .details-vote-statistics-vote__result-voted-label {
        font-size: 16px;
        line-height: 162%;
    }
    .details-vote-statistics-vote__quorum {
        gap: 4px;
        margin-bottom: 16px;
    }
    .details-vote-statistics-vote__quorum-label,
    .details-vote-statistics-vote__status-event-label,
    .details-vote-statistics-vote__progress-visit-vote-label {
        font-size: 14px;
        line-height: 157%;
    }
    .details-vote-statistics-vote__quorum-note {
        font-size: 16px;
        line-height: 162%;
    }
    .details-vote-statistics-vote__status-event {
        gap: 4px;
        margin-bottom: 16px;
    }
    .details-vote-statistics-vote__status-event-icon {
        gap: 6px;
        font-size: 16px;
        line-height: 162%;
    }
    .details-vote-statistics-vote__status-event-icon img {
        width: 12px;
        height: 9px;
    }
    .details-vote-statistics-vote__status-event-icon-not-reached {
        gap: 6px;
        font-size: 16px;
        line-height: 162%;
    }
    .details-vote-statistics-vote__status-event-icon-not-reached img {
        width: 12px;
        height: 9px;
    }
    .details-vote-statistics-vote__progress-visit-vote {
        gap: 8px;
    }
    .details-vote-statistics-vote__progress {
        height: 8px;
    }
    .details-vote-statistics-vote__progress-bar {
        height: 8px;
    }
    .details-vote-statistics-vote__show-more-btn {
        font-size: 14px;
        line-height: 171%;
    }
    .details-vote-statistics-vote__dynamics-visit-title {
        font-size: 21px;
        font-weight: 500;
        line-height: 138%;
        margin-bottom: 16px;
    }
    .details-vote-statistics-vote__dynamics-visit-change-graph-table {
        gap: 8px;
    }
    .details-vote-statistics-vote__change-graph-btn {
        width: 40px;
        height: 40px;
    }
    .details-vote-statistics-vote__change-graph-btn:hover {
        width: 40px;
        height: 40px;
    }
    .details-vote-statistics-vote__change-table-btn {
        width: 40px;
        height: 40px;
    }
    .details-vote-statistics-vote__change-table-btn:hover {
        width: 40px;
        height: 40px;
    }
    .details-vote-statistics-vote__change-graph-btn.active {
        width: 40px;
        height: 40px;
        object-fit: cover;
    }
    .details-vote-statistics-vote__change-table-btn.active {
        width: 40px;
        height: 40px;
        object-fit: cover;
    }
    .details-vote-statistics-vote__dynamics-visit-graph {
        flex-direction: column-reverse;
    }
    .details-vote-statistics-vote__visit-graph-explanations {
        margin-bottom: 0;
        gap: 16px;
    }
    .details-vote-statistics-vote__visit-graph {
        margin-bottom: 16px;
        height: 210px;
    }
    .details-vote-statistics-vote__visit-graph-explanations-general-visit,
    .details-vote-statistics-vote__visit-graph-explanations-voting-visit {
        gap: 8px;
    }
    .details-vote-statistics-vote__visit-graph-explanations-general-visit img,
    .details-vote-statistics-vote__visit-graph-explanations-voting-visit img {
        width: 8px;
    }
    .details-vote-statistics-vote__visit-graph-explanations-general-visit p,
    .details-vote-statistics-vote__visit-graph-explanations-voting-visit p {
        font-size: 16px;
        line-height: 162%;
    }
    .details-vote-statistics-vote__roll-up-btn {
        font-size: 14px;
        line-height: 171%;
    }
    .details-vote-statistics-vote__visit-table-header-time {
        padding: 15px 16px 15px 16px;
        font-size: 16px;
        line-height: 150%;
        margin-right: 16px;
    }
    .details-vote-statistics-vote__visit-table-header-visit-time {
        padding: 15px 16px 15px 0;
        font-size: 16px;
        line-height: 150%;
        margin-right: 16px;
    }
    .details-vote-statistics-vote__visit-table-header-general-visit {
        padding: 15px 16px 15px 0;
        font-size: 16px;
        line-height: 150%;
    }
    .details-vote-statistics-vote__visit-table-row-colimn-time {
        padding: 15px 16px 15px 16px;
        font-size: 16px;
        line-height: 150%;
        margin-right: 16px;
    }
    .details-vote-statistics-vote__visit-table-row-colimn-visit-time {
        padding: 15px 16px 15px 0;
        font-size: 16px;
        line-height: 150%;
        margin-right: 16px;
    }
    .details-vote-statistics-vote__visit-table-row-colimn-general-visit {
        padding: 15px 16px 15px 0;
        font-size: 16px;
        line-height: 150%;
    }
    .details-vote-statistics-vote__empty-data-visit-voters {
        font-size: 16px;
    }
    .details-vote-statistics-vote__empty-table-date {
        font-size: 16px;
        padding: 16px 0 16px 0;
    }
    .details-vote-statistics-vote-update-statistics-vote__button {
        font-size: 16px;
    }
    .details-vote-statistics-vote-update-statistics-vote {
        position: relative;
        flex-direction: column;
        margin-bottom: 24px;
    }
    .details-vote-statistics-vote-update-statistics-vote__message {
        font-size: 14px;
        position: absolute;
        margin: 55px;
        width: 100%;
    }
}
@media (max-width: 440px) {
    .details-vote-statistics-vote__hidden-block {
       width: 312px;
        left: 0;
    }
}
@media (max-width: 375px) {
    .diagramm-circle {
        margin: 0 auto;
    }
    .details-vote-statistics-vote__diagramm-block-numbers-reg-voting {
        left: 28%;
    }
    .details-vote-statistics-vote__diagramm-block-numbers-voting {
        left: 31%;
    }
    .details-vote-statistics-vote__visit-graph {
        height: 210px;
    }

}