.details-votes-page-result-question-grid {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(54, 59, 77, 0.2);
    padding-bottom: 1.33em;
    margin-bottom: 1.33em;
}
.details-votes-page-result-question-grid:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
}
.details-votes-page-result-question-grid__title-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 1.33em 0;
    gap: 0.88em;
}
.details-votes-page-result-question-grid__title {
    font-weight: 600;
    font-size: 1em;
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
    margin: 0 auto 0 0;
}
.details-votes-page-result-question-grid__rule {
    font-weight: 400;
    font-size: 0.88em;
    line-height: 133%;
    color: #363B4D;
    opacity: 0.4;
}
.details-votes-page-result-question-grid__tables-container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(54, 59, 77, 0.3);
    border-radius: 1.33em;
    margin: 0 auto 0 0;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 650px) {
    .details-votes-page-result-question-grid__title-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 16px;
    }
    .details-votes-page-result-question-grid__title {
        font-size: 16px;
        font-weight: 500;
    }
    .details-votes-page-result-question-grid__rule {
        font-size: 12px;
    }
    .details-votes-page-result-question-grid {
        margin-bottom: 16px;
    }
}