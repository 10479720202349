.org-settings {
    display: flex;
    flex-direction: column;
    margin-bottom: 9em;
    min-height: calc(49vh - 80px);
    font-size: 18px;
}
.org-settings__page-title-container {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin: 0 auto 0 0;
}
.org-settings__crumbs-container {
    display: flex;
    flex-direction: row;
    gap: 0.8em;
    margin: 0 auto 1.5em 0;
    align-items: center;
}
.org-settings__crumb {
    line-height: 118%;
    font-size: 0.7em;
    color: #363B4D;
    opacity: 40%;
}
.org-settings__arrow {
    width: 0.3em;
    height: 0.8em;
}
.org-settings__title-container {
    display: flex;
    margin: 0 auto 0 0;
}
.org-settings__title {
    font-size: 1.7em;
    font-weight: 600;
    color: #363B4D;
    opacity: 90%;
    line-height: 103%;
    margin: auto 13px auto 0;
}
.org-settings__edit-title {
    background-image: url('../../img/org-edit-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 2.5em;
    min-height: 2.5em;
    margin: auto 0;
    cursor: pointer;
}
.org-settings__edit-title:hover {
    background-image: url('../../img/org-edit-icon-hover.svg');
}
.org-settings__edit-title-container {
    display: flex;
    margin: 0 auto 0 0;
}
.org-settings__edit-title-input {
    font-size: 1.7em;
    font-weight: 600;
    color: #363B4D;
    opacity: 90%;
    line-height: 103%;
    margin: auto 13px auto 0;
    background-color: #FBFBFC;
}
.org-settings__edit-title-input::placeholder {
    font-size: 0.7em;
    font-weight: 400;
}
.org-settings__edit-title-input:focus {
    outline: none;
}
.org-settings__edit-title-buttons-container {
    display: flex;
    margin: auto 0;
}
.org-settings__edit-title-button-save {
    width: 9.5em;
    height: 2em;
    border: 1px solid #4ED4A9;
    border-radius: 4px;
    background-color: #FBFBFC;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4ED4A9;
    margin: auto 8px auto 0;
    cursor: pointer;
}
.org-settings__edit-title-button-save:hover {
    background-color: #4ED4A9;
    color: #FFFFFF;
}
.org-settings__edit-title-button-cancel {
    width: 9.5em;
    height: 2em;
    border: 1px solid #FF4970;
    border-radius: 4px;
    background-color: #FBFBFC;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FF4970;
    margin: auto 0;
    cursor: pointer;
}
.org-settings__edit-title-button-cancel:hover {
    background-color: #FF4970;
    color: #FFFFFF;
}
.org-settings__main-container {
    display: flex;
    margin: 1.7em 0;
}
.org-settings__main-container-mobile {
    display: none;
    flex-direction: column;
    margin: 1.7em 0;
}
.org-settings__nav-container {
    display: flex;
    flex-direction: column;
    max-width: 343px;
    min-width: 343px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(54, 58, 72, 0.08);
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    margin: 0 16px auto 0;
}
.org-settings__nav-container-mobile {
    display: none;
    flex-direction: column;
    max-width: 343px;
    min-width: 343px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(54, 58, 72, 0.08);
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    margin: 0 16px auto 0;
}
.org-settings__link-container {
    display: flex;
    border-radius: 16px;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(54, 59, 77, 1);
}
.org-settings__link-container:hover {
    background: rgba(0, 132, 254, 0.07);
    color: #0084FE;
    cursor: pointer;
}
.org-settings__link-container_active {
    background: rgba(0, 132, 254, 0.07);
    color: #0084FE;
}
.org-settings__link-container_active:hover {
    cursor: default;
}
.org-settings__link-container-red {
    display: flex;
    border-radius: 16px;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FF4970;
}
.org-settings__link-container-red:hover {
    background: #f7becb;
    cursor: pointer;
}
.org-settings__link-container-green {
    display: flex;
    border-radius: 16px;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #4ED4A9;
}
.org-settings__link-container-green:hover {
    background: #c6f7e7;
    cursor: pointer;
}
.org-settings__link-container_admins:hover .org-settings__link-icon_admins {
    background-image: url('../../img/admin-list-hover.svg');
}
.org-settings__link-container_users:hover .org-settings__link-icon_users {
    background-image: url('../../img/DetailsVoteIconVotingHover.svg');
}
.org-settings__link-container_statistics:hover .org-settings__link-icon_statistics {
    background-image: url('../../img/DetailsVoteIconStatisticsHover.svg');
}
.org-settings__link-container_general:hover .org-settings__link-icon_general {
    background-image: url('../../img/general-settings-hover.svg');
}
.org-settings__link-container_protocol:hover .org-settings__link-icon_protocol {
    background-image: url('../../img/protocol-settings-hover.svg');
}
.org-settings__link-container_vote:hover .org-settings__link-icon_vote {
    background-image: url('../../img/vote-constructor-hover.svg');
}
.org-settings__link-container_mailing:hover .org-settings__link-icon_mailing {
    background-image: url('../../img/mailing-settings-hover.svg');
}
.org-settings__link-container_subscription:hover .org-settings__link-icon_subscription {
    background-image: url('../../img/subscription-hover.svg');
}
.org-settings__link-icon {
    min-width: 24px;
    min-height: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto 16px auto 40px;
}
.org-settings__link-icon_admins {
    background-image: url('../../img/admin-list.svg');
}
.org-settings__link-icon_users {
    background-image: url('../../img/DetailsVoteIconVoting.svg');
}
.org-settings__link-icon_statistics {
    background-image: url('../../img/DetailsVoteIconStatistics.svg');
}
.org-settings__link-icon_general {
    background-image: url('../../img/general-settings.svg');
}
.org-settings__link-icon_protocol {
    background-image: url('../../img/protocol-settings.svg');
}
.org-settings__link-icon_vote {
    background-image: url('../../img/vote-constructor.svg');
}
.org-settings__link-icon_mailing {
    background-image: url('../../img/mailing-settings.svg');
}
.org-settings__link-icon_subscription {
    background-image: url('../../img/subscription.svg');
}
.org-settings__link-icon_admins-active {
    background-image: url('../../img/admin-list-hover.svg');
}
.org-settings__link-icon_user-active {
    background-image: url('../../img/DetailsVoteIconVotingHover.svg');
}
.org-settings__link-icon_statistics-active {
    background-image: url('../../img/DetailsVoteIconStatisticsHover.svg');
}
.org-settings__link-icon_general-active {
    background-image: url('../../img/general-settings-hover.svg');
}
.org-settings__link-icon_protocol-active {
    background-image: url('../../img/protocol-settings-hover.svg');
}
.org-settings__link-icon_vote-active {
    background-image: url('../../img/vote-constructor-hover.svg');
}
.org-settings__link-icon_mailing-active {
    background-image: url('../../img/mailing-settings-hover.svg');
}
.org-settings__link-icon_subscription-active {
    background-image: url('../../img/subscription-hover.svg');
}
.org-settings__link-icon_block {
    background-image: url('../../img/lock-icon.svg');
}
.org-settings__link-icon_unlock {
    background-image: url('../../img/unlock-icon.svg');
}
.org-settings__link-icon_delete {
    background-image: url('../../img/delete-icon.svg');
}
.org-settings__link-text {
    margin: 16px auto 16px 0;
}
.org-settings__container {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(54, 58, 72, 0.08);
    border-radius: 16px;
    padding: 1.77em;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
}
.org-settings__container-mobile {
    display: none;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(54, 58, 72, 0.08);
    border-radius: 16px;
    padding: 32px;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
}
.org-settings__mobile-heading-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 16px 0;
}
.org-settings__mobile-button-back-container {
    display: flex;
    margin: 0 auto 0 0;
}
.org-settings__mobile-button-back-arrow {
    background-image: url('../../img/back-button-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: auto 8px auto 0;
}
.org-settings__mobile-button-back-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #363B4D;
    opacity: 0.4;
    margin: auto auto auto 0;
}
.org-settings__mobile-settings-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(54, 59, 77, 1);
    margin: 16px auto 0 0;
    max-width: 300px;
}
@media (max-width: 1900px) {
    .org-settings  {
        font-size: 16px;
        min-height: calc(65vh - 80px);
    }
    .org-settings__page-title-container {
        font-size: 16px;
    }
}
@media (max-width: 1735px) {
    .org-settings  {
        font-size: 14px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1570px) {
    .org-settings  {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1395px) {
    .org-settings  {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1350px) {
    .org-settings  {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1180px) {
    .org-settings  {
        font-size: 6px;
        min-height: calc(65vh - 80px);
        overflow: auto;
    }
}
@media (max-width: 1120px) {
    .org-settings__page-title-container {
        font-size: 14px;
    }
}
@media (max-width: 950px) {
    .org-settings  {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
    .org-settings__main-container {
        display: none;
    }
    .org-settings__main-container-mobile {
        display: flex;
    }
    .org-settings__nav-container {
        display: none;
    }
    .org-settings__nav-container-mobile {
        display: flex;
    }
    .org-settings__container {
        display: none;
    }
    .org-settings__container-mobile {
        display: flex;
        padding: 16px;
    }
}
@media (max-width: 830px) {
    .org-settings  {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
    .org-settings__page-title-container {
        font-size: 12px;
    }
}
@media (max-width: 650px) {
    .org-settings  {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
    .org-settings__edit-title-button-save {
        width: 6.5em;
    }
    .org-settings__edit-title-button-cancel {
        width: 6.5em;
    }
}
@media (max-width: 600px) {
    .org-settings__page-title-container {
        font-size: 10px;
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .org-settings__page-title-container {
        font-size: 16px;
        margin-bottom: 14px;
    }
    .org-settings__crumbs-container {
        display: none;
    }
    .org-settings__title {
        font-size: 20px;
        line-height: 113%;
        font-weight: 500;
    }
    .org-settings__edit-title {
        min-width: 2.0em;
        min-height: 2.0em;
    }
    .org-settings__edit-title-container {
        flex-direction: column;
    }
    .org-settings__edit-title-input {
        font-size: 20px;
        margin: 0 auto 0 0;
    }
    .org-settings__edit-title-buttons-container {
        display: flex;
        margin: 15px 0 0;
    }
    .org-settings__edit-title-button-save {
        width: 100%;
    }
    .org-settings__edit-title-button-cancel {
        width: 100%;
    }
}