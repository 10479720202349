/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-vote-questions__container {
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em 2.22em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    align-self: flex-start;
    width: 68.889vw;
}
.details-vote-results__recreate-protocol-title {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.details-vote-questions__title {
    font-size: 1.33rem;
    line-height: 133%;
    font-weight: 600;
    margin-bottom: 0.88em;
    color: rgba(54, 59, 77, 1);
}
.details-vote-results__buttons-block {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
.details-vote-results__recreate-protocol-load-protocol {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 0.88em;
}
.details-vote-questions__recreate-protocol-btn {
    width: 18.66em;
    height: 3em;
    border-radius: 0.44em;
    background: none;
    font-size: 0.88em;
    border: 1px solid #0084FE;
    color: #0084FE;
    cursor: pointer;
}
.details-vote-questions__export-protocol-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18.66em;
    height: 3em;
    border-radius: 0.44em;
    background: none;
    font-size: 0.88em;
    border: 1px solid #0084FE;
    color: #0084FE;
    cursor: pointer;
    text-decoration: none;
}
.details-vote-questions__sign-protocol-btn {
    width: 22em;
    padding: 0 0.22em 0 0.22em;
    height: 3em;
    border-radius: 0.44em;
    background: none;
    font-size: 0.88em;
    border: 1px solid #0084FE;
    color: #0084FE;
    cursor: pointer;
}
.details-vote-questions__send-opinion-btn {
    width: 22em;
    padding: 0 0.22em 0 0.22em;
    height: 3em;
    border-radius: 0.44em;
    background: none;
    font-size: 0.88em;
    border: 1px solid #FF8A00;
    color: #FF8A00;
    cursor: pointer;
}
.details-vote-questions__export-protocol-btn:hover,
.details-vote-questions__sign-protocol-btn:hover {
    background: #0084FE;
    color: #FFFFFF;
}
.details-vote-questions__send-opinion-btn:hover {
    background: #FF8A00;
    color: #FFFFFF;
}
.details-vote-questions__recreate-protocol-btn.active,
.details-vote-questions__send-opinion-btn.active {
    pointer-events: none;
    background: darkgray;
    border: none;
}
.details-vote-questions__recreate-protocol-btn:hover {
    background: #0084FE;
    color: #FFFFFF;
}
.details-vote-questions__title-mobile {
    display: none;
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 650px) {
    .details-vote-questions__container {
        width: 100%;
        padding: 16px 16px 24px 16px;
    }
    .details-vote-questions__title-mobile {
        display: initial;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 144%;
        font-weight: 500;
        color: rgba(54, 59, 77, 1);
    }
    .details-vote-questions__title {
        display: none;
    }
    .details-vote-questions__recreate-protocol-btn,
    .details-vote-questions__send-opinion-btn,
    .details-vote-questions__export-protocol-btn,
    .details-vote-questions__sign-protocol-btn {
        font-size: 12px;
        height: 40px;
        width: 100%;
    }
    .details-vote-results__buttons-block {
        flex-direction: column;
    }
    .details-vote-results__recreate-protocol-title {
        margin-bottom: 0.88em;
    }
    .details-vote-results__buttons-block {
        gap: 0;
    }
}