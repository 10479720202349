/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-vote__container {
  display: flex;
  flex-direction: column;
  min-height: calc(49vh - 80px);
  font-size: 18px;
  margin-bottom: 6.22em;
}
/*------Контейнер для главного блока----------------------------------------------------------------------------------*/
.details-vote__main-block {
  display: flex;
  flex-direction: row;
  gap: 0.88em;
}
.details-vote__row-back-votes-mobile {
  display: none;
}
/*------Контейнер для блока-меню--------------------------------------------------------------------------------------*/
.details-vote__information-menu {
  display: flex;
  flex-direction: column;
  padding: 0.88em 0.88em;
  box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
  background-color: #ffffff;
  border-radius: 1.33em;
  align-self: flex-start;
  min-width: 26.111vw;
}
.details-vote__information-menu.hidden {
  display: none;
}
.details-vote__menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.88em;
  height: 3.33em;
  width: 100%;
  cursor: pointer;
  padding: 0.88em 0 0.88em 2.22em;
  color: rgba(54, 59, 77, 1);
}
.details-vote__menu-items.active {
  color: #0084fe;
  border-radius: 0.88em;
  background-color: rgba(0, 132, 254, 0.05);
  cursor: default;
}
.details-vote__menu-items:hover {
  color: #0084fe;
  border-radius: 0.88em;
  background-color: rgba(0, 132, 254, 0.05);
}
.details-vote__menu-label {
  line-height: 156%;
  font-size: 1em;
}
.details-vote__icon-general-info {
  background-image: url("../../img/DetailsVoteIconInfo.svg");
  width: 1.33em;
  height: 1.33em;
  background-size: cover;
  background-repeat: no-repeat;
}
.details-vote__icon-statistics {
  background-image: url("../../img/DetailsVoteIconStatistics.svg");
  width: 1.33em;
  height: 1.33em;
  background-size: cover;
  background-repeat: no-repeat;
}
.details-vote__icon-voting {
  background-image: url("../../img/DetailsVoteIconVoting.svg");
  width: 1.33em;
  height: 1.33em;
  background-size: cover;
  background-repeat: no-repeat;
}
.details-vote__icon-observers {
  background-image: url("../../img/DetailsVoteIconObservers.svg");
  width: 1.33em;
  height: 1.33em;
  background-size: cover;
  background-repeat: no-repeat;
}
.details-vote__icon-counting {
  background-image: url("../../img/admin-list.svg");
  width: 1.33em;
  height: 1.33em;
  background-size: cover;
  background-repeat: no-repeat;
}
.details-vote__icon-questions {
  background-image: url("../../img/DetailsVoteIconKnowQuestions.svg");
  width: 1.33em;
  height: 1.33em;
  background-size: cover;
  background-repeat: no-repeat;
}
.details-vote__menu-items:hover .details-vote__icon-general-info {
  background-image: url("../../img/DetailsVoteIconInfoHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items.active .details-vote__icon-general-info {
  background-image: url("../../img/DetailsVoteIconInfoHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items:hover .details-vote__icon-statistics {
  background-image: url("../../img/DetailsVoteIconStatisticsHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items.active .details-vote__icon-statistics {
  background-image: url("../../img/DetailsVoteIconStatisticsHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items:hover .details-vote__icon-voting {
  background-image: url("../../img/DetailsVoteIconVotingHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items.active .details-vote__icon-voting {
  background-image: url("../../img/DetailsVoteIconVotingHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items:hover .details-vote__icon-observers {
  background-image: url("../../img/DetailsVoteIconObserversHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items.active .details-vote__icon-observers {
  background-image: url("../../img/DetailsVoteIconObserversHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items:hover .details-vote__icon-counting {
  background-image: url("../../img/admin-list-hover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items.active .details-vote__icon-counting {
  background-image: url("../../img/admin-list-hover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items:hover .details-vote__icon-questions {
  background-image: url("../../img/DetailsVoteIconKnowQuestionsHover.svg");
  width: 1.33em;
  height: 1.33em;
}
.details-vote__menu-items.active .details-vote__icon-questions {
  background-image: url("../../img/DetailsVoteIconKnowQuestionsHover.svg");
  width: 1.33em;
  height: 1.33em;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1420px) {
  .details-vote__container {
    font-size: 16px;
  }
}
@media (max-width: 1265px) {
  .details-vote__container {
    font-size: 14px;
  }
}
@media (max-width: 1115px) {
  .details-vote__container {
    font-size: 12px;
    min-height: calc(40vh - 50px);
  }
}
@media (max-width: 960px) {
  .details-vote__container {
    font-size: 10px;
  }
}
@media (max-width: 810px) {
  .details-vote__container {
    font-size: 8px;
  }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 650px) {
  .details-vote__main-block {
    flex-direction: column;
  }
  .details-vote__row-back-votes-mobile {
    display: flex;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 14px;
    line-height: 143%;
    color: rgba(54, 59, 77, 0.4);
  }
  .details-vote__link-row {
    display: inline-flex;
    align-items: center;
    gap: 11px;
    font-size: 14px;
    line-height: 143%;
    color: rgba(54, 59, 77, 0.4);
    text-decoration: none;
  }
  .details-vote__information-menu {
    width: 100%;
    padding: 16px 16px;
  }
  .details-vote__menu-items {
    height: 48px;
    padding: 0;
    gap: 10px;
  }
  .details-vote__menu-items:hover,
  .details-vote__menu-items.active {
    background: none;
    color: rgba(54, 59, 77, 1);
    cursor: pointer;
  }
  .details-vote__menu-label {
    font-size: 16px;
  }
  .details-vote__icon-general-info,
  .details-vote__icon-statistics,
  .details-vote__icon-voting,
  .details-vote__icon-observers,
  .details-vote__icon-counting,
  .details-vote__icon-questions {
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items:hover .details-vote__icon-general-info {
    background-image: url("../../img/DetailsVoteIconInfo.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items.active .details-vote__icon-general-info {
    background-image: url("../../img/DetailsVoteIconInfo.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items:hover .details-vote__icon-statistics {
    background-image: url("../../img/DetailsVoteIconStatistics.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items.active .details-vote__icon-statistics {
    background-image: url("../../img/DetailsVoteIconStatistics.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items:hover .details-vote__icon-voting {
    background-image: url("../../img/DetailsVoteIconVoting.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items.active .details-vote__icon-voting {
    background-image: url("../../img/DetailsVoteIconVoting.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items:hover .details-vote__icon-observers {
    background-image: url("../../img/DetailsVoteIconObservers.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items.active .details-vote__icon-observers {
    background-image: url("../../img/DetailsVoteIconObservers.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items:hover .details-vote__icon-counting {
    background-image: url("../../img/admin-list.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items.active .details-vote__icon-counting {
    background-image: url("../../img/admin-list.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items:hover .details-vote__icon-questions {
    background-image: url("../../img/DetailsVoteIconKnowQuestions.svg");
    width: 20px;
    height: 20px;
  }
  .details-vote__menu-items.active .details-vote__icon-questions {
    background-image: url("../../img/DetailsVoteIconKnowQuestions.svg");
    width: 20px;
    height: 20px;
  }
}

.statistics-page .details-vote-statistics-vote__container,
.statistics-page .details-vote-results__container {
  width: 100%;
  max-width: 1328px;
  margin: 25px auto;
}

.statistics-page .details-vote-statistics-vote__title,
.statistics-page .details-vote-statistics-vote__title-and {
  font-size: 32px;
}

.statistics-page .details-vote-statistics-vote__result-registred-items {
  font-size: 48px;
}

.statistics-page .details-vote-statistics-vote__diagramm-num-reg {
  font-size: 80px;
  line-height: 1;
}

.statistics-page .details-vote-statistics-vote__diagramm-label-reg {
  font-size: 24px;
}

.statistics-page .details-vote-statistics-vote__status-event-quorum-condition,
.statistics-page .details-vote-statistics-vote__dynamics-visit-title {
  font-size: 32px;
}

.statistics-page .details-vote-general-info__results-diagramm-block {
  display: block;
}

.statistics-page .details-vote-statistics-vote__quorum {
  margin-bottom: 48px;
}
.statistics-page .details-vote-statistics-vote__result-registred {
  flex-direction: column;
}

.statistics-page .details-vote-statistics-vote__diagramm-block,
.statistics-page .diagramm-circle {
  width: 305px;
  height: 305px;
}

.statistics-page .details-vote-general-info__title-name-vote {
  font-size: 40px;
  margin-bottom: 32px;
}

.statistics-page .group-users-select-name-group__button {
  display: inline-flex;
  margin-left: auto;
}

.statistics-page .details-vote-statistics-vote__status-event-quorum-condition {
  display: none;
}

.statistics-page .details-vote-questions__title {
  font-size: 32px;
}

.statistics-page .details-votes-page-result-card-graph__column-list {
  font-size: 30px;
  gap: 20px;
  flex-grow: 1;
}

.statistics-page .card-question-vertical-graph-wide-column__name-column {
  font-size: 32px;
}

.statistics-page .details-votes-page-result-question-row__title {
  font-size: 40px;
}

.statistics-page .details-vote-results__recreate-protocol-load-protocol {
  display: none;
}

.statistics-page
  .details-votes-page-result-votes-card__switch-table-gistogramma {
  display: none;
}

.statistics-page .card-question-horizontal-graph__horizontal-grid {
    gap: 40px;
}

.statistics-page .card-question-horizontal-graph-column-row__column-svg rect {
    max-width: 100%;
}

.statistics-page .card-question-horizontal-graph-column-row__columns-text-percent {
    font-size: 48px;
    position: static;
}

.statistics-page .card-question-horizontal-graph-column-row__columns {
    flex-direction: row;
    align-items: center;
    gap: 24px;
    max-width: 100%;
}

.statistics-page .card-question-horizontal-graph-column-row__columns-text-percent.active {
    display: flex;
    flex-shrink: 0;
}

.statistics-page .card-question-horizontal-graph-column-row__column-svg {
    flex-shrink: 0;
    width: 445px;
    max-width: 100%;
}

.statistics-page .card-question-horizontal-graph__horizontal-grid {
    height: 400px;
    width: 720px;
    max-width: 100%;
    flex-shrink: 0;
}


.statistics-page .details-votes-page-result-card-graph__wrapper {
    gap: 80px;
    height: 300px;
}

.statistics-page .horizontal-grid__position-table {
    display: none;
}

.statistics-page .card-question-horizontal-graph-column-row__column-svg rect {
    height: 58px;
}

.statistics-page .graph-square {
    width: 24px !important;
    height: 24px !important;
}

.statistics-page .card-question-graph-column__name-column {
    font-size: 48px;
    line-height: 1.2;
}